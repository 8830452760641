import React from "react";

import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import StorageOutlinedIcon from "@material-ui/icons/StorageOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import SwapHorizOutlinedIcon from "@material-ui/icons/SwapHorizOutlined";
import FlashOnOutlinedIcon from "@material-ui/icons/FlashOnOutlined";
import AspectRatioOutlinedIcon from "@material-ui/icons/AspectRatioOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import StorageRoundedIcon from "@material-ui/icons/StorageRounded";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import RestoreOutlinedIcon from "@material-ui/icons/RestoreOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import FilterNoneOutlinedIcon from "@material-ui/icons/FilterNoneOutlined";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";

const icons = {
  SettingsOutlinedIcon: <SettingsOutlinedIcon />,
  StorageOutlinedIcon: <StorageOutlinedIcon />,
  PeopleOutlineOutlinedIcon: <PeopleOutlineOutlinedIcon />,
  MapOutlinedIcon: <MapOutlinedIcon />,
  SwapHorizOutlinedIcon: <SwapHorizOutlinedIcon />,
  FlashOnOutlinedIcon: <FlashOnOutlinedIcon />,
  AspectRatioOutlinedIcon: <AspectRatioOutlinedIcon />,
  ExitToAppOutlinedIcon: <ExitToAppOutlinedIcon />,
  SystemUpdateAltOutlinedIcon: <SystemUpdateAltOutlinedIcon />,
  AssignmentOutlinedIcon: <AssignmentOutlinedIcon />,
  LinkOutlinedIcon: <LinkOutlinedIcon />,
  StorageRoundedIcon: <StorageRoundedIcon />,
  NotificationsNoneOutlinedIcon: <NotificationsNoneOutlinedIcon />,
  RestoreOutlinedIcon: <RestoreOutlinedIcon />,
  VpnKeyOutlinedIcon: <VpnKeyOutlinedIcon />,
  EmailOutlinedIcon: <EmailOutlinedIcon />,
  ContactMailOutlinedIcon: <ContactMailOutlinedIcon />,
  FilterNoneOutlinedIcon: <FilterNoneOutlinedIcon />,
  FilterListOutlinedIcon: <FilterListOutlinedIcon />,
  TableChartOutlinedIcon: <TableChartOutlinedIcon />,
  PermContactCalendarOutlinedIcon: <PermContactCalendarOutlinedIcon />,
};

export default icons;
