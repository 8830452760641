import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    paddingTop: "20px",
    width: "150px",
    minWidth: "150px",
    borderRight: "1px solid #ddd",
  },
  main: {
    flex: 1,
    backgroundColor: "#f3f3f3",
  },
  container: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    height: "80px",
    color: "#666",
    padding: "20px 4px 0 4px",
    marginBottom: "20px",
    "&:hover": { cursor: "pointer" },
  },
  itemContainerSelected: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    height: "80px",
    color: theme.palette.primary.main,
    padding: "20px 4px 0 0",
    marginBottom: "20px",
    borderLeft: "4px solid " + theme.palette.primary.main,
    "&:hover": { cursor: "pointer" },
  },
}));

const Sidebar = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const currentLocation = window.location.pathname;

  const subPages = props.subpages.filter((x) => !x.root).length;

  let pages = null;
  if (props.subpages) {
    pages = props.subpages.map((page) => {
      const isSelected = currentLocation.startsWith(page.location);
      if (page.root) return null;
      return (
        <Box
          key={page.name}
          className={
            isSelected ? classes.itemContainerSelected : classes.itemContainer
          }
          onClick={() => navigateTo(page.location)}
        >
          <Box>{page.icon}</Box>
          <Box>{page.name}</Box>
        </Box>
      );
    });
  }

  const navigateTo = (route) => {
    history.push(route);
  };

  return (
    <Box className={classes.container}>
      {subPages > 0 ? <Box className={classes.sidebar}>{pages}</Box> : null}
      <Box className={classes.main}>{props.children}</Box>
    </Box>
  );
};

export default Sidebar;
