import React from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const ActionBar = (props) => {
  // const theme = useTheme();
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    flex: {
      flex: 1,
    },
    actionbar: {
      backgroundColor: "transparent",
      borderBottom: "1px solid #ddd",
      height: "65px",
      paddingLeft: 8,
    },
    whiteButton: {
      color: "#fff",
    },
    tallWhiteButton: {
      color: theme.palette.primary.main,
      marginRight: 4,
      marginLeft: 4,
      opacity: 0.9,
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}19`,
      },
    },
    tallWhiteButtonSelected: {
      color: theme.palette.primary.main,
      marginRight: 4,
      marginLeft: 4,
      opacity: 1,
      backgroundColor: `${theme.palette.primary.main}19`,
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}19`,
      },
    },
  }));

  const navigateTo = (route) => {
    history.push(route);
  };

  const classes = useStyles();

  const currentLocation = window.location.pathname;

  const pages = props.pages ? [...props.pages] : [];
  console.log(pages);
  let actionbar = null;

  if (pages && pages.length > 0) {
    actionbar = (
      <React.Fragment>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.actionbar}
        >
          {pages.map((item) => (
            <Grid item key={item.location}>
              <Button
                className={
                  currentLocation === item.location
                    ? classes.tallWhiteButtonSelected
                    : classes.tallWhiteButton
                }
                variant="text"
                onClick={() => navigateTo(item.location)}
              >
                {item.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </React.Fragment>
    );
  }

  return actionbar;
};

export default ActionBar;
