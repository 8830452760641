import React, { useEffect, useState } from "react";
import VideoLesson from "../VideoLesson";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

import pageIcons from "./pageIcons";

const Pages = (props) => {
  const [sidebarItems, setSidebarItems] = useState();

  useEffect(() => {
    const loadItems = async () => {
      const baseServerUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3040"
          : "/api";
      const token = props.token;
      const config = { headers: { Authorization: `Bearer ${token}` } };
      try {
        const mydata = await axios.get(
          `${baseServerUrl}/pages/${props.page}`,
          config
        );
        const sidebar = mydata.data.map((item) => {
          let newIcon = null;
          if (item.icon) newIcon = pageIcons[item.icon];
          return { ...item, icon: newIcon };
        });

        setSidebarItems(sidebar);
      } catch (e) {
        console.log(e);
      }
    };
    loadItems();
  }, [props.token, props.page]);

  let page = <CircularProgress />;
  if (!!sidebarItems) {
    page = (
      <VideoLesson subpages={sidebarItems} displayName={props.displayName} />
    );
  }
  return page;
};

export default Pages;
