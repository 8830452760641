import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const OutlineTag = (props) => {
  const useStyles = makeStyles((theme) => ({
    outlined: {
      border: "1px solid",
      borderRadius: "5px",
      padding: "3px 10px",
      color: props.color,
      borderColor: props.color,
      fontSize: "13px",
      letterSpacing: "0.3px",
      marginRight: 16,
      backgroundColor: "#fff",
      fontWeight: 600,
    },
  }));
  const classes = useStyles();

  return <p className={classes.outlined}>{props.text}</p>;
};

export default OutlineTag;
