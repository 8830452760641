import React from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
}));

const SideDrawer = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (route) => {
    props.setSideDrawerClosed();
    history.push(route);
  };

  const sidebarItems = [
    { name: "Welcome", to: "/welcome" },
    { name: "Quick Start", to: "/quickstart" },
    { divider: true },
    { name: "Dashboard", to: "/dashboard" },
    { name: "Users", to: "/users" },
    { name: "Sources", to: "/sources" },
    { name: "Destinations", to: "/destinations" },
    { name: "Logs", to: "/logs" },
    { name: "Settings", to: "/settings" },
    { divider: true },
    { name: "User Settings", to: "/usersettings" },
    { name: "Release Notes", to: "/releasenotes" },
    { name: "Help", to: "/help" },
    { name: "Log Out", to: "/logout" },
  ];

  const currentLocation = window.location.pathname;

  return (
    <SwipeableDrawer
      anchor="left"
      open={props.sideDrawerOpen}
      onClose={props.setSideDrawerClosed}
      onOpen={props.setSideDrawerOpen}
    >
      <div className={classes.list} role="presentation">
        <List>
          {sidebarItems.map((item, index) =>
            item.divider ? (
              <Divider key={index} />
            ) : (
              <ListItem
                selected={currentLocation.startsWith(item.to)}
                key={index}
                button
                onClick={() => handleClick(item.to)}
              >
                <ListItemText primary={item.name} />
              </ListItem>
            )
          )}
        </List>
      </div>
    </SwipeableDrawer>
  );
};

SideDrawer.propTypes = {};

export default SideDrawer;
