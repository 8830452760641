import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import BorderAllOutlinedIcon from "@material-ui/icons/BorderAllOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import StorageOutlinedIcon from "@material-ui/icons/StorageOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";

import Logo from "./Logo";
import OutlineTag from "./OutlineTag";

const MaterialToolbar = (props) => {
  // const theme = useTheme();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (route) => {
    setAnchorEl(null);
    navigateTo(route);
  };

  const useStyles = makeStyles((theme) => ({
    flex: {
      flex: 1,
    },
    whiteButton: {
      color: "#fff",
    },
    tallWhiteButton: {
      color: "#fff",
      marginRight: 0,
      paddingLeft: 23,
      paddingRight: 23,
      textTransform: "none",
      fontWeight: 400,
      opacity: 0.9,
      height: theme.mixins.toolbar.minHeight,
      borderRadius: 0,
      "&:hover": {
        backgroundColor: "#ffffff19",
      },
    },
    tallWhiteButtonSelected: {
      color: "#fff",
      marginRight: 0,
      paddingLeft: 23,
      paddingRight: 23,
      textTransform: "none",
      fontWeight: 400,
      opacity: 1,
      height: theme.mixins.toolbar.minHeight,
      borderRadius: 0,
      backgroundColor: "#ffffff19",
      "&:hover": {
        backgroundColor: "#ffffff19",
      },
    },
  }));

  const navigateTo = (route) => {
    history.push(route);
  };

  const classes = useStyles();

  const currentLocation = window.location.pathname;

  const navItems = [
    {
      name: "Dashboard",
      icon: <BorderAllOutlinedIcon />,
      link: "/dashboard",
    },
    {
      name: "Users",
      icon: <PersonOutlineOutlinedIcon />,
      link: "/users",
    },
    {
      name: "Sources",
      icon: <ContactMailOutlinedIcon />,
      link: "/sources",
    },
    {
      name: "Destinations",
      icon: <StorageOutlinedIcon />,
      link: "/destinations",
    },
    {
      name: "Logs",
      icon: <TrendingUpOutlinedIcon />,
      link: "/logs",
    },
  ];

  return (
    <AppBar position="static" color="primary" elevation="0">
      <Toolbar>
        <React.Fragment>
          <Hidden mdUp>
            <IconButton
              edge="start"
              className={classes.whiteButton}
              aria-label="menu"
              onClick={props.openSideDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <Tooltip title="Welcome">
              <span>
                <Logo click={() => navigateTo("/welcome")} />
              </span>
            </Tooltip>
            <OutlineTag text="beta" color="#e48d20" />
            {navItems.map((item) => (
              <Button
                key={item.link}
                className={
                  currentLocation.startsWith(item.link)
                    ? classes.tallWhiteButtonSelected
                    : classes.tallWhiteButton
                }
                variant="text"
                startIcon={item.icon}
                onClick={() => navigateTo(item.link)}
              >
                {item.name}
              </Button>
            ))}
          </Hidden>
          <div className={classes.flex} />
          <Tooltip title="Quick Start">
            <IconButton
              className={classes.whiteButton}
              disableRipple
              onClick={() => navigateTo("/quickstart")}
            >
              <DirectionsRunIcon />
            </IconButton>
          </Tooltip>
          <IconButton
            className={classes.whiteButton}
            disableRipple
            onClick={() => navigateTo("/settings")}
          >
            <SettingsOutlinedIcon />
          </IconButton>
          <IconButton
            className={classes.whiteButton}
            onClick={handleClick}
            disableRipple
          >
            <AccountCircleOutlinedIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleClose("/usersettings")}>
              User Settings
            </MenuItem>
            <MenuItem onClick={() => handleClose("/releasenotes")}>
              Release Notes
            </MenuItem>
            <MenuItem onClick={() => handleClose("/help")}>Help</MenuItem>
            <MenuItem onClick={() => handleClose("/logout")}>Log Out</MenuItem>
          </Menu>
        </React.Fragment>
      </Toolbar>
    </AppBar>
  );
};

MaterialToolbar.propTypes = {
  openSideDrawer: PropTypes.func,
};

export default MaterialToolbar;
