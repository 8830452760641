import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#008AD7" },
    type: "light",
  },
  mixins: {
    toolbar: {
      minHeight: 50,
    },
  },
});

export default theme;
