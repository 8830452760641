import React from "react";
import "typeface-roboto";
import "./App.css";
import {
  Route,
  Redirect,
  withRouter,
  useHistory,
  Switch,
} from "react-router-dom";
import Layout from "./components/General/Layout";
import Login from "./components/Login/Login";
import Code from "./components/Login/Code";
import Pages from "./components/Pages/Pages";

const App = (props) => {
  const token = localStorage.getItem("token");
  const displayName = localStorage.getItem("name");
  const isAuthenticated = !!token;

  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    history.push("/login");
  };

  const topRoutes = [
    "welcome",
    "quickstart",
    "dashboard",
    "users",
    "sources",
    "destinations",
    "logs",
    "settings",
    "usersettings",
    "releasenotes",
    "help",
  ];

  let page = (
    <Switch>
      <Route path="/code" exact component={Code} />
      <Route path="/login" exact component={Login} />
      <Redirect to="/login" />
    </Switch>
  );

  if (isAuthenticated) {
    page = (
      <Layout>
        <Switch>
          {topRoutes.map((page) => (
            <Route path={`/${page}`} key={page}>
              <Pages token={token} page={page} displayName={displayName} />
            </Route>
          ))}
          <Route
            path="/logout"
            exact
            render={() => {
              handleLogout();
            }}
          />
          <Redirect to={`/${topRoutes[0]}`} />
        </Switch>
      </Layout>
    );
  }

  return page;
};

export default withRouter(App);
