import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";

const Code = (props) => {
  const history = useHistory();
  const { location } = props;

  useEffect(() => {
    const handleCode = async () => {
      const baseUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3040"
          : "https://onesync.oboesax.com/api";
      let success = true;
      if (location && location.search) {
        const parms = queryString.parse(location.search);
        if (
          parms.code &&
          parms.response_type &&
          parms.response_type === "code"
        ) {
          const payload = { code: parms.code };
          try {
            const ret = await axios.post(`${baseUrl}/exchangecode`, payload);
            if (ret.status === 200) {
              localStorage.setItem("token", ret.data.token);
              localStorage.setItem("name", ret.data.name);
            } else {
              success = false;
              console.log("error: ", ret.status);
            }
          } catch (e) {
            success = false;
            console.log("http post error: ", e);
          }
        } else {
          success = false;
          console.log("that's not a code");
        }
      }
      if (success) history.push("/");
      else history.push("/login");
    };

    handleCode();
  }, [history, location]);

  return <React.Fragment>finishing login...</React.Fragment>;
};

export default withRouter(Code);
