import React from "react";
import YouTube from "react-youtube";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const MyYouTube = (props) => {
  const useStyles = makeStyles((theme) => ({
    videoDiv: {
      marginTop: ".5rem",
    },
    soon: {
      border: `1px solid ${theme.palette.grey["400"]}`,
      color: theme.palette.grey["400"],
      backgroundColor: "#222",
      margin: ".5rem auto",
      borderRadius: 5,
      [theme.breakpoints.only("xs")]: {
        paddingTop: "100px",
        height: "240px",
        width: "426px",
      },
      [theme.breakpoints.only("sm")]: {
        paddingTop: "160px",
        height: "360px",
        width: "640px",
      },
      [theme.breakpoints.only("md")]: {
        paddingTop: "220px",
        height: "480px",
        width: "854px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingTop: "350px",
        height: "720px",
        width: "1280px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingTop: "500px",
        height: "1080px",
        width: "1920px",
      },
    },
  }));

  const classes = useStyles();

  const opts = {
    height: "360",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 0,
    },
  };
  if (props.size === "1080p") {
    opts.height = "1080";
    opts.width = "1920";
  }
  if (props.size === "720p") {
    opts.height = "720";
    opts.width = "1280";
  }
  if (props.size === "480p") {
    opts.height = "480";
    opts.width = "854";
  }
  if (props.size === "360p") {
    opts.height = "360";
    opts.width = "640";
  }
  if (props.size === "240p") {
    opts.height = "240";
    opts.width = "426";
  }

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  if (!props.videoId)
    return (
      <div className={classes.videoDiv}>
        <div className={classes.soon}>
          <Typography variant="body2">Coming soon...</Typography>
        </div>
      </div>
    );
  else {
    const videoArray = (Array.isArray(props.videoId)) ? props.videoId : Array(props.videoId);
    return (
      <React.Fragment>
        {videoArray.map((videoId) => (
          <div className={classes.videoDiv} key={videoId}>
            {videoId ? (
              <YouTube videoId={videoId} opts={opts} onReady={onReady} />
            ) : (
              <div className={classes.soon}>
                <Typography variant="body2">Coming soon...</Typography>
              </div>
            )}
          </div>
        ))}
      </React.Fragment>
    );
  }

  // return (
  //   <div className={classes.videoDiv}>
  //     {props.videoId ? (
  //       <YouTube videoId={props.videoId} opts={opts} onReady={onReady} />
  //     ) : (
  //       <div className={classes.soon}>
  //         <Typography variant="body2">Coming soon...</Typography>
  //       </div>
  //     )}
  //   </div>
  // );
};

export default MyYouTube;
