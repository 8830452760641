import React from "react";
import YouTube from "./YouTube";
import { Route } from "react-router-dom";
import Sidebar from "./General/Sidebar";
import ActionBar from "./General/ActionBar";
import Breadcrumbs from "./General/Breadcrumbs";

import Hidden from "@material-ui/core/Hidden";
import { createMuiTheme } from "@material-ui/core/styles";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

const VideoLesson = (props) => {
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640 * 1.08 + 150,
        md: 854 * 1.08 + 150,
        lg: 1280 * 1.08 + 150,
        xl: 1920 * 1.08 + 150,
      },
    },
  });

  const currentLocation = window.location.pathname;

  const subsubpages = props.subpages
    .filter((sp) => sp.subpages !== undefined && sp.subpages.length > 0)
    .map((page) => page.subpages);

  const pages = [...props.subpages].concat(...subsubpages);

  let PageTitle = "";
  props.subpages.forEach((page) => {
    if (page.root) {
      PageTitle = page.name;
    }
  });

  let currentSubPages = [];
  props.subpages.forEach((page) => {
    if (currentLocation.startsWith(page.location) && !page.root) {
      PageTitle = PageTitle + " > " + page.name;
      if (page.subpages)
        currentSubPages = currentSubPages.concat(...page.subpages);
    }
  });

  const hasSubpages = props.subpages.filter((page) => !page.root).length > 0;

  return (
    <Sidebar subpages={props.subpages}>
      <ActionBar pages={currentSubPages} />
      <Breadcrumbs pages={pages} hasSubpages={hasSubpages} />
      {pages.map((page) => {
        return (
          <Route path={page.location} exact key={page.name}>
            <ThemeProvider theme={theme}>
              {/* <Typography variant="h3">{page.name}</Typography> */}
              <Hidden smUp>
                <YouTube videoId={page.videoId} size="240p" />
              </Hidden>
              <Hidden xsDown mdUp>
                <YouTube videoId={page.videoId} size="360p" />
              </Hidden>
              <Hidden smDown lgUp>
                <YouTube videoId={page.videoId} size="480p" />
              </Hidden>
              <Hidden mdDown xlUp>
                <YouTube videoId={page.videoId} size="720p" />
              </Hidden>
              <Hidden lgDown>
                <YouTube videoId={page.videoId} size="1080p" />
              </Hidden>
            </ThemeProvider>
          </Route>
        );
      })}
    </Sidebar>
  );
};

export default VideoLesson;
