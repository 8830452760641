import React from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    margin: ".5rem auto",
    fontWeight: 600,
    textAlign: "left",
    [theme.breakpoints.between(0, 640 * 1.08 + 150)]: {
      width: "426px",
    },
    [theme.breakpoints.between(640 * 1.08 + 150, 854 * 1.08 + 150)]: {
      width: "640px",
    },
    [theme.breakpoints.between(854 * 1.08 + 150, 1280 * 1.08 + 150)]: {
      width: "854px",
    },
    [theme.breakpoints.between(1280 * 1.08 + 150, 1920 * 1.08 + 150)]: {
      width: "1280px",
    },
    [theme.breakpoints.up(1920 * 1.08 + 150)]: {
      width: "1920px",
    },
  },
  crumbLink: {
    color: theme.palette.primary.light,
    "&:hover": {
      cursor: "pointer",
    },
  },
  crumb: {
    color: theme.palette.text.secondary,
  },
  chevron: {
    margin: "0px 16px",
    color: theme.palette.text.secondary,
  },
}));

const Breadcrumbs = (props) => {
  const history = useHistory();
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640 * 1.08 + 150,
        md: 854 * 1.08 + 150,
        lg: 1280 * 1.08 + 150,
        xl: 1920 * 1.08 + 150,
      },
    },
  });

  const navigateTo = (route) => {
    history.push(route);
  };

  const classes = useStyles();

  const currentLocation = window.location.pathname;

  const subsubpages = props.pages
    .filter((sp) => sp.subpages !== undefined && sp.subpages.length > 0)
    .map((page) => page.subpages);

  const pages = [...props.pages].concat(...subsubpages);

  const splitlocation = currentLocation.split("/").filter((c) => c.length > 0);
  const breadcrumbs = [];
  // let fullPath = "";
  for (let i = 0, fullPath = ""; i < splitlocation.length; i++) {
    fullPath = fullPath + "/" + splitlocation[i];
    const found = pages.filter((p) => p.location === fullPath);
    if (found && found.length > 0) {
      const b = found[0];
      if (i < splitlocation.length - 1) {
        breadcrumbs.push(
          <span
            className={classes.crumbLink}
            key={b.location}
            onClick={() => navigateTo(b.location)}
          >
            {b.name}
          </span>
        );
        breadcrumbs.push(<span className={classes.chevron}>{">"}</span>);
      } else {
        breadcrumbs.push(<span className={classes.crumb}>{b.name}</span>);
      }
    }
  }
  console.log(breadcrumbs);

  let breadcrumbDisplay = null;
  if (props.hasSubpages) {
    breadcrumbDisplay = (
      <div className={classes.breadcrumbs}>
        <React.Fragment>{breadcrumbs}</React.Fragment>
      </div>
    );
  }

  return <ThemeProvider theme={theme}>{breadcrumbDisplay}</ThemeProvider>;
};

export default Breadcrumbs;
