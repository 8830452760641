import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import osLogo from "../../assets/logo_fill_small.png";

const Logo = (props) => {
  const useStyles = makeStyles((theme) => ({
    LogoDiv: {
      padding: "11px 0 0 0",
      height: theme.mixins.toolbar.minHeight,
      width: theme.mixins.toolbar.minHeight,
      [theme.breakpoints.up("xs")]: {
        height: 48,
        marginRight: 6,
      },
      [theme.breakpoints.up("sm")]: {
        height: theme.mixins.toolbar.minHeight,
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
    w40: {
      width: props.width ? props.width : "40px",
    },
  }));
  const classes = useStyles();

  return (
    <div onClick={props.click} className={classes.LogoDiv}>
      <img src={osLogo} alt="bankie logo" className={classes.w40} />
    </div>
  );
};

export default Logo;
